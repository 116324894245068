<template>
  <div style="height: 100%; background: #f1f5f6">
    <div class="high_quality_selection_view">
      <div class="see_more_view" @click="toQualitySelection">
        <span>查看更多</span>
        <img src="../assets/quality_selection_arrow_bg.png" alt />
      </div>
      <van-list
        class="list_view"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="module_getItemAndCartNum"
      >
        <van-cell
          @click="toGoodsDetail(item)"
          class="cell_view"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="list_item_view">
            <div class="image_view">
              <img
                v-show="(item.stockingTime == 1 || item.stockingTime == 2) && item.totalStock <= 0"
                class="torrmow_img"
                src="@/assets/ic_torrmow.png"
                alt
              />
              <img
                class="img"
                style="position: absolute"
                v-show="item.totalStock<= 0 && item.stockingTime >= 30"
                src="@/assets/shoujin_bg.png"
              />
              <img class="photo_view" :src="imgApp + item.image" alt />
            </div>
            <div class="goods_info_view">
              <span class="goods_name_view">{{ item.name }}</span>
              <span class="goods_guige_view">{{ item.guige }}</span>
              <div class="price_view">
                <div class="span_view">
                  <span style="color: #df3436; font-size: 12px">₱</span>
                  <span style="color: #df3436; font-size: 18px">
                    {{
                    item.price
                    }}
                  </span>
                </div>
                <div class="add_view">
                  <van-badge max="100">
                    <img src="../assets/add_view_bg.png" alt />
                  </van-badge>
                </div>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div style="height: 60px"></div>
    </div>
  </div>
</template>


<script>
import {
  goods_getList,
  imgApp,
  module_getItemAndCartNum
} from "@/service/api/index.js";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      imgApp,
      list: [],
      pageIndex: 1,
      pageSize: 9
    };
  },
  mounted() {
    // this.module_getItemAndCartNum();
  },
  methods: {
    module_getItemAndCartNum() {
      module_getItemAndCartNum({
        id: 2,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.status == 200) {
          this.pageIndex++;
          const { vos = [] } = res.data || {};
          this.list.push(...vos);
          this.loading = false;
          if (vos.length == 0) {
            this.finished = true;
          }
        } else if (res.status == 401) {
          this.$router("/login");
        }
      });
    },
    onLoad() {
      setTimeout(() => {
        this.module_getItemAndCartNum();
      }, 1000);
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   // 加载状态结束
      //   this.loading = false;
      //   // 数据全部加载完成
      //   if (this.list.length >= 6) {
      //     this.finished = true;
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    toGoodsDetail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: item.id
        }
      });
    },
    toQualitySelection() {
      this.$router.push("/qualitySelection");
    }
  }
};
</script>

<style lang="less" scoped>
.torrmow_img {
  width: 70px;
  position: absolute;
  z-index: 10;
  left: 0px;
  // top: 10px;
}
// @media screen and (max-width:540px) {
.high_quality_selection_view {
  width: 100%;
  height: 209px;
  position: relative;
  background-image: url("../assets/high_quality_selection_bg.png");
  background-size: 100% 209px;
  background-repeat: no-repeat;
  .see_more_view {
    width: 70px;
    height: 18px;
    border: 0.6px solid #b48b53;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    color: #b48b53;
    justify-content: center;
    font-size: 10px;
    right: 15px;
    top: 25px;
    img {
      width: 7px;
      height: 7px;
    }
  }
  .list_view {
    width: 100%;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    .cell_view {
      width: 96%;
      border-radius: 8px;
      margin-bottom: 15px;
      box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);
      .list_item_view {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);
        .image_view {
          width: 110px;
          display: flex;
          position: relative;
          margin-left: 7px;
          margin-top: 12px;
          .img {
            width: 91px;
            height: 91px;
            border-radius: 1px;
          }
          .photo_view {
            width: 91px;
            height: 91px;
            border-radius: 1px;
          }
          .ys_view {
            width: 90px;
            height: 20px;
            position: absolute;
            left: 0%;
            top: 34px;
          }
          .hour_view {
            width: 74px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
          }
          .sj_view {
            width: 67px;
            height: 67px;
            position: absolute;
            top: 11px;
            left: 11px;
          }
        }
        .goods_info_view {
          width: 100%;
          margin-left: 16px;
          margin-top: 10px;
          .goods_name_view {
            font-size: 14px;
            color: #3f3f3f;
          }
          .goods_guige_view {
            font-size: 12px;
            color: #999999;
            display: block;
          }
          .price_view {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .add_view {
              margin-right: 12px;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
// }
// @media screen and (min-width:768px) {
//     .high_quality_selection_view{
//         width: 100%;
//         height: 296px;
//         position: relative;
//         background-image: url('../assets/high_quality_selection_bg.png');
//         background-size: 100% 296px;
//         background-repeat: no-repeat;
//         .see_more_view{
//             width: 99px;
//             height: 25px;
//             border: 0.8px solid #B48B53;
//             border-radius: 42px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             position: absolute;
//             color: #B48B53;
//             justify-content: center;
//             font-size: 14.2px;
//             right: 21px;
//             top: 35px;
//             img{
//                 width: 9.9px;
//                 height: 9.9px;
//             }
//         }
//         .list_view{
//             width: 100%;
//             position: absolute;
//             top: 99px;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             background: white;
//             .cell_view{
//                 width: 96%;
//                 border-radius: 11px;
//                 margin-bottom: 21px;
//                 box-shadow: 0px 14.2px 14.2px 0px rgba(153, 153, 153, 0.1);
//                 .list_item_view{
//                     display: flex;
//                     flex-direction: row;
//                     box-shadow: 0px 14.2px 14.2px 0px rgba(153, 153, 153, 0.1);
//                 .image_view{
//                     width: 156px;
//                     display: flex;
//                     position: relative;
//                     margin-left: 9.9px;
//                     margin-top: 17px;
//                     .photo_view{
//                         width: 129px;
//                         height: 129px;
//                         border-radius: 50%;
//                         background: violet;
//                     }
//                     .ys_view{
//                         width: 127px;
//                         height: 28px;
//                         position: absolute;
//                         left: 0%;
//                         top: 48px;
//                     }
//                     .hour_view{
//                         width: 105px;
//                         height: 28px;
//                         position: absolute;
//                         left: 0;
//                         top:0;
//                     }
//                     .sj_view{
//                         width: 95px;
//                         height: 95px;
//                         position: absolute;
//                         top: 15px;
//                         left: 15px;
//                     }
//                 }
//                 .goods_info_view{
//                     width: 100%;
//                     margin-left: 22px;
//                     margin-top: 14.2px;
//                     .goods_name_view{
//                         font-size: 19px;
//                         color: #3F3F3F;
//                     }
//                     .goods_guige_view{
//                         font-size: 17px;
//                         color: #999999;
//                         display: block;
//                     }
//                     .price_view{
//                         display: flex;
//                         flex-direction: row;
//                         justify-content: space-between;
//                         .add_view{
//                             margin-right: 17px;
//                             img{
//                                 width: 28px;
//                                 height: 28px;
//                             }

//                         }
//                     }
//                 }
//             }
//             }

//         }
//     }
// }
</style>