<template>
    <div class="container_view">
        <div class="icon_view">
            <div class="icon_item_quality_view">
                <img src="../assets/quality_bg.png" alt="">
                <span>正品保障</span>
            </div>
            <div class="line_view"></div>
            <div class="icon_item_distribution_view">
                <img src="../assets/distribution_bg.png" alt="">
                <span>全城配送</span>
            </div>
            <div class="line_view"></div>
            <div class="icon_item_timely_view">
                <img src="../assets/timely_bg.png" alt="">
                <span>及时送达</span>
            </div>
            <div class="line_view"></div>
            <div class="icon_item_after_view">
                <img src="../assets/after_sales_bg.png" alt="">
                <span>售后无忧</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
    // @media screen and (max-width:540px) {
        .icon_view{
            width: 100%;
            height: 74px;
            display: flex;
            justify-content: space-around;
            color: #3F3F3F;
            font-size: 12px;
            align-items: center;
            flex-direction: row;
            .icon_item_quality_view,.icon_item_distribution_view,.icon_item_timely_view,.icon_item_after_view{
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    width: 22px;
                    height: 22px;
                    margin-right: 2px;
                }
            }
            .line_view{
                width: 1px;
                height: 15px;
                background: #E1E1E1;
            }
        }
    // }
    // @media screen and (min-width:768px) {
    //     .container_view{
    //         max-width: 540px;
    //     }
    //     .icon_view{
    //     width: 100%;
    //     height: 105px;
    //     display: flex;
    //     justify-content: space-around;
    //     color: #3F3F3F;
    //     font-size: 17px;
    //     flex-direction: row;
    //     .icon_item_quality_view,.icon_item_distribution_view,.icon_item_timely_view,.icon_item_after_view{
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         img{
    //             width: 31px;
    //             height: 31px;
    //             margin-right: 3.4px;
    //         }
    //     }
    // }
    // }
    
</style>