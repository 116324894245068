import Vue from 'vue';
import{
  Image as VanImage,
  ActionSheet,
  IndexBar,
  IndexAnchor,
  Button,
  Badge,
  CountDown,
  Stepper,
  Search,
  Swipe,
  Switch,
  SwipeItem,
  Lazyload,
  Icon,
  Tag,
  List,
  Loading,
  Cell,
  Dialog,
  DropdownMenu,
  DropdownItem,
  Empty,
  Toast,
  TreeSelect,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  Col,
  Row,
  Grid,
  RadioGroup,
  Radio,
  GridItem,
  Form,
  Field,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Sku,
  Checkbox,
  CheckboxGroup,
  Popup,
  Card,
  SubmitBar,
  SwipeCell,
  Uploader,
  NavBar,
  Picker,
  DatetimePicker 
} from 'vant'
Vue.use(ActionSheet);
Vue.use(Button);
Vue.use(Badge);
Vue.use(CountDown);
Vue.use(SwipeCell);
Vue.use(SubmitBar);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Sku);
Vue.use(GoodsAction);
Vue.use(RadioGroup);
Vue.use(Radio)
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(Field);
Vue.use(Form);
Vue.use(Stepper);
Vue.use(Search);
Vue.use(Switch);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Tag);
Vue.use(List);
Vue.use(Loading);
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Cell);
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Empty);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(TreeSelect);
Vue.use(Tabbar);
Vue.use(Tabs);
Vue.use(TabbarItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(Picker);
Vue.use(DatetimePicker);

