import ajax from './ajax'

// http://demo.itlike.com/web/xlmc/api/homeApi
// http://demo.itlike.com/web/xlmc/api/homeApi/categories
// http://demo.itlike.com/web/xlmc/api/homeApi/categoriesdetail/lk001
// lk001 - lk006

// const app = 'https://api.freshph.online/system';
const app = process.env.VUE_APP_BASE_API;
export const app2 = process.env.VUE_APP_BASE_API.split('/', 3).join('/');
export const imgApp = 'https://api.freshph.online/';
export const getGoodsCart = (user_id) => ajax(LOCAL_BASE_URL + '/api/cart/search/' + user_id);
// export const pwdLogin = (user_name, user_pwd, captcha) => ajax(LOCAL_BASE_URL + '/api/login_pwd', {user_name, user_pwd, captcha}, 'POST');
//规格
export const getBySPu = (preParams) => ajax(app + '/spec/getBySPu', preParams);


//首页
export const index = (user_id) => ajax(app + '/index/user/index');
export const goods_getList = (preParams) => ajax(app + '/goods/getList', preParams);
export const order_page = (preParams) => ajax(app + '/order/page', preParams);
export const order_determine = (preParams) => ajax(app + '/order/determine', preParams);
export const AlipayWebH5 = (preParams) => ajax(app + '/pay/AlipayWebH5', preParams);
export const WxWebPayH5 = (preParams) => ajax(app + '/pay/WxWebPayH5', preParams);
export const recharge_callback = (preParams) => ajax(app + '/pay/recharge/callback', preParams);
export const order_toSettle = (preParams) => ajax(app + '/order/toSettle', preParams, "PUT");
export const goods_hotGoods = (preParams) => ajax(app + '/goods/hotGoods', preParams);
export const order_getOrderItem = (preParams) => ajax(app + '/order/getOrderItem', preParams);
export const arrive_timer = (preParams) => ajax(app + "/business-set/get", preParams)
export const adManage_getList = (preParams) => ajax(app + '/adManage/getList', preParams);
export const pay_AliPayH5 = (preParams) => ajax(app + '/pay/AliPayH5', preParams);
export const pay_WxPayH5 = (preParams) => ajax(app + '/pay/WxPayH5', preParams);
export const geocoder = (preParams) => ajax('https://apis.map.qq.com/ws/geocoder/v1', preParams);
export const pay_recharge_callback = (preParams) => ajax(app + '/pay/recharge/callback', preParams);
export const module_getItemAndCartNum = (preParams) => ajax(app + '/module/getItemAndCartNum', preParams);
export const upload_upImg = (preParams) => ajax(app + '/upload/upImg', preParams, 'POST');
export const captcha_sendSms = (preParams) => ajax(app2 + '/captcha/sms/sendSms', new URLSearchParams(preParams), 'POST');
// 修改购物车商品数量
export const cartUpdate = (preParams) => ajax(app + '/cart/update', new URLSearchParams(preParams), 'PUT');
export const login_user_callBack = (preParams) => ajax(app + '/login/user/callBack', new URLSearchParams(preParams), 'POST');
export const login_bandPhone = (preParams) => ajax(app + '/login/user/bandPhone', preParams, 'PUT');
export const order_onceGenerateOrder = (preParams) => ajax(app + '/order/onceGenerateOrder', preParams, 'PUT');
export const pay_createOrderAli = (preParams) => ajax(app + '/pay/createOrderAli', new URLSearchParams(preParams), 'PUT');
//取消订单
export const order_cancelOrder = (preParams) => ajax(app + '/order/cancelOrder', new URLSearchParams(preParams), 'PUT');
export const reach_cash = (preParams) => ajax(app + '/pay/reachCash', new URLSearchParams(preParams), 'PUT');
export const update_password = (preParams) => ajax(app + '/login/user/updatePassword', preParams, 'PUT');
export const update_pw = (preParams) => ajax(app2 + '/system/user/updatePw', preParams, 'PUT');
export const recommendSpu = (preParams) => ajax(app + '/goods/recommendSpu', preParams, 'GET');
export const getById = (preParams) => ajax(app + '/user/getById', preParams, 'GET');
export const update = (preParams) => ajax(app + '/user/update', preParams, 'PUT');
// export const update = (preParams) => ajax(app + '/user/update', preParams, 'POST');
//一级分类列表
export const getListOne = () => ajax(app + '/category/getListOne');
//根据一级id查询二级分类列表
export const getList = (preParams) => ajax(app + '/category/getList', preParams);
//根据二级分类id查询商品
export const getGoodsList = (preParams) => ajax(app + '/category/getGoodsList', preParams);
//分类商品
export const getAllItem = (preParams) => ajax(app + '/category/getAllItem', preParams);
//根据商品spuId查询商品详情
export const getGoodsDetails = (preParams) => ajax(app + '/goods/getById', preParams);
//用户登陆
export const pwdLogin = (preParams) => ajax(app + '/login/user/login', preParams, 'POST');
// 用户验证验证码
export const updatePassword = (preParams) => ajax(app + '/login/user/updatePassword', preParams, 'PUT');
// (用户端)根据token查询用户
export const usergGtById = (preParams) => ajax(app + '/user/getById', preParams);
// 添加一件商品到购物车(或者给一个sku加数量)
export const cartAdd = (preParams) => ajax(app + '/cart/add', preParams, 'POST');
// 根据用户id查询购物车
export const cartGet = (preParams) => ajax(app + '/cart/get/', preParams);
// (批量)删除购物车商品
export const deleteOne = (preParams) => ajax(app + '/cart/deleteOne/', preParams, 'DELETE');
// 进入搜索商品页面
export const getSearch = (preParams) => ajax(app + '/user/getSearch', preParams, 'GET');
// 搜索商品
export const getListByWrapper = (keyWord) => ajax(app + '/goods/getListByWrapper', keyWord, 'GET');
//添加搜索记录
export const searchHistory = (keyWord) => ajax(app + '/searchHistory/add', new URLSearchParams(keyWord), 'POST');
//清空搜索历史
export const clearSearchHistory = (keyWord) => ajax(app + '/user/clearSearchHistory',keyWord, 'GET');
//腾讯地图搜索附近
export const tencentSearch = (preParams) => ajax(app + '/tencent/map/search', preParams, 'GET');
//腾讯地图周边推荐
export const tencentExplore = (preParams) => ajax(app + '/tencent/map/explore', preParams, 'GET');
//腾讯地图驾车路线规划
export const tencentDriving = (preParams) => ajax(app + '/tencent/map/driving', preParams, 'GET');
//谷歌地图定位服务
export const googleLocation = (preParams) => ajax("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBTlD-bEI5BUzPIVid9tBDXMzLwVDgVOYM", preParams, 'POST');
//谷歌地图搜索服务
// export const googleSearch = (preParams) => ajax(app + '/google/map/search', preParams, 'GET');
export const googleSearch = (preParams) => ajax(app + '/google/map/search', preParams, 'GET');




// (用户端 订单)根据用户token和订单状态查询订单;全部订单All(0),待付款NOPAY(1),待配送NODELIVERY(3),配送中DELIVERYING(4),已完成COMPLETE(5),已取消CANCLE(6),未送达NOREACH(7)
export const ItemByOrderStatus = (preParams) => ajax(app + '/user/ItemByOrderStatus', preParams);
//查询运费模版
export const getFreightByReceiptId = (preParams) => ajax(app + '/freightManage/getFreightByReceiptId', new URLSearchParams(preParams),'PUT');
//购物车下单
export const generateCartOrder = (preParams) => ajax(app + '/order/generateCartOrder', new URLSearchParams(preParams),'PUT');
//查询最早送达时间
export const getTimeByIds = (preParams) => ajax(app + '/goods/getTimeByIds',preParams,'POST');
//查询可选配送时间
export const getDeliveryTime = (preParams) => ajax(app + '/goods/getDeliveryTime',preParams,'POST_PARAMS');
//确认收货
export const confirmArrive = (preParams) => ajax(app + '/order/confirmArrive', new URLSearchParams(preParams),'PUT');
//修改配送时间
export const updateDeliveryTime = (preParams) => ajax(app + '/order/updateDeliveryTime', new URLSearchParams(preParams),'PUT');
//未送达重新选择配送时间
export const selectDeliveryTime = (preParams) => ajax(app + '/order/selectDeliveryTime', new URLSearchParams(preParams), 'PUT');
//获取商家经纬度
export const getShop = (preParams) => ajax(app + '/index/getShop',preParams);
// 获取配送员和收货地址经纬度
export const setLngLat = (preParams) => ajax(app + '/delivery/setLngLat',preParams);



// 查询收货地址列表
export const receiptGetList = (preParams) => ajax(app + '/receipt/getList', preParams);
// 新增收货地址
export const receiptAdd = (preParams) => ajax(app + '/receipt/add', preParams, 'POST');
// 删除收货地址
export const receiptDelete = (preParams) => ajax(app + '/receipt/delete/', preParams, 'DELETE');
// 查询默认收货地址
export const getDefault = (preParams) => ajax(app + '/receipt/getDefault', preParams);
// 订单选择收货地址
export const receiptSelect = (preParams) => ajax(app + '/receipt/select/', preParams, 'DELETE');
// 修改收货地址
export const receiptUpdate = (preParams) => ajax(app + '/receipt/update', preParams, 'PUT');




// 营业时间获取
export const getBusiness = (preParams) => ajax(app + '/business-set/get', preParams);
// (用户端)获取汇率
export const getExchange = (preParams) => ajax(app + '/user/getExchange', preParams);


// 1. 定义基础路径
const BASE_URL = 'https://demo.itlike.com/web/xlmc';

export const getHomeData = () => ajax(BASE_URL + '/api/homeApi');
export const getCategories = () => ajax(BASE_URL + '/api/homeApi/categories');
export const getCategoriesDetail = (preParams) => ajax(BASE_URL + '/api/homeApi/categoriesdetail' + preParams);





// 2. 用户中心接口
//  http://localhost:3000/web/xlmc/api/send_code
const LOCAL_BASE_URL = 'https://demo.itlike.com/web/xlmc';
// const LOCAL_BASE_URL = '/api';

// 2.1 获取短信验证码(GET)
export const getPhoneCode = (phone) => ajax(LOCAL_BASE_URL + '/api/send_code', { phone });
// 2.2 手机验证码登录(POST)
export const phoneCodeLogin = (phone, code) => ajax(LOCAL_BASE_URL + '/api/login_code', { phone, code }, 'POST');
// 2.3 用户名和密码登录(POST)
// export const pwdLogin = (user_name, user_pwd, captcha) => ajax(LOCAL_BASE_URL + '/api/login_pwd', {user_name, user_pwd, captcha}, 'POST');
// 2.4 自动登录
export const getUserInfo = () => ajax(LOCAL_BASE_URL + '/api/userinfo');
// 2.5 退出登录
export const getLogOut = () => ajax(LOCAL_BASE_URL + '/api/logout');




// 3. 购物车接口
/*
3.1 添加商品
请求方式：POST
参数：{goods_id: String, goods_name: String, goods_price: Number, small_image: String}
*/
export const addGoodsToCart = (user_id, goods_id, goods_name, goods_price, small_image) => ajax(LOCAL_BASE_URL + '/api/cart/add', { user_id, goods_id, goods_name, goods_price, small_image }, 'POST');

// 3.2 获取当前用户购物车中的商品
// export const getGoodsCart = (user_id) => ajax(LOCAL_BASE_URL+ '/api/cart/search/'+ user_id);

// 3.3 修改购物车商品数量
export const changeCartNum = (user_id, goods_id, type) => ajax(LOCAL_BASE_URL + '/api/cart/num', { user_id, goods_id, type }, 'POST');

// 3.4 删除当前用户购物车中所有的商品
export const clearAllCart = (user_id) => ajax(LOCAL_BASE_URL + '/api/cart/clear/' + user_id);

// 3.5 单个商品的选中和取消选中
export const singerGoodsSelect = (user_id, goods_id) => ajax(LOCAL_BASE_URL + '/api/cart/singer_select', { user_id, goods_id }, 'POST');

// 3.6 所有商品的选中和取消选中
export const allGoodsSelect = (user_id, flag) => ajax(LOCAL_BASE_URL + '/api/cart/all_select', { user_id, flag }, 'POST');

// 3.7 查询所有已经被选中的商品
export const getAllSelectedGoods = (user_id) => ajax(LOCAL_BASE_URL + '/api/cart/selected/' + user_id);

// 3.8 删除已经生成订单的商品
export const delAllSelectedGoods = (user_id) => ajax(LOCAL_BASE_URL + '/api/cart/del_checked/' + user_id);


// 4. 地址接口

// 4.1 获取当前用户的地址
export const getUserAddress = (user_id) => ajax(LOCAL_BASE_URL + '/api/address/search/' + user_id);

// 4.2 添加新的地址
export const addUserAddress = (user_id, address_name, address_phone, address_area, address_area_detail, address_post_code, address_tag, province, city, county, areaCode) => ajax(LOCAL_BASE_URL + '/api/address/add', { user_id, address_name, address_phone, address_area, address_area_detail, address_post_code, address_tag, province, city, county, areaCode }, 'POST');

// 4.3 编辑用户的地址
export const changeUserAddress = (address_id, user_id, address_name, address_phone, address_area, address_area_detail, address_post_code, address_tag, province, city, county, areaCode) => ajax(LOCAL_BASE_URL + '/api/address/edit', { address_id, user_id, address_name, address_phone, address_area, address_area_detail, address_post_code, address_tag, province, city, county, areaCode }, 'POST');

// 4.4 删除用户的地址
export const delUserAddress = (address_id) => ajax(LOCAL_BASE_URL + '/api/address/del/' + address_id);

// 4.5 获取单条地址
export const getCurrentUserAddress = (user_id, address_id) => ajax(LOCAL_BASE_URL + '/api/address/one', { user_id, address_id }, 'POST');

// 5. 订单接口
// 5.1 提交订单
export const postOrder = (user_id, address_id, arrive_time, cart_shop, notice, shop_price, dis_price) => ajax(LOCAL_BASE_URL + '/api/order/post', { user_id, address_id, arrive_time, cart_shop, notice, shop_price, dis_price }, 'POST');

// 5.2 订单支付成功
export const orderPaySuccess = (user_id, order_id) => ajax(LOCAL_BASE_URL + '/api/order/change_status', { user_id, order_id }, 'POST');

// 5.3 查询订单
export const getOrder = (user_id, status) => ajax(LOCAL_BASE_URL + '/api/order/get', { user_id, status }, 'POST'); // pay will


// 6. 微信支付接口部署
/*支付接口白名单：demo.itlike.com */
const PAY_URL = 'https://47.98.157.152/WXPayProject/pay';
// const PAY_URL = '/pay';
// 6.1 获取支付的URL
export const getWXCode = (outTradeNo, totalFee) => ajax(PAY_URL + '/createNative.do', { outTradeNo, totalFee });
// 6.2 查询是否支付成功
export const queryPayStatus = (out_trade_no) => ajax(PAY_URL + '/queryPayStatus.do', { out_trade_no });

