<template>
  <div class="container_view">
    <div class="user_top_view" @click="toUserCenter">
      <div class="img_div">
        <img :src="info.headImg ? info.headImg : require('../../assets/user_photo_bg.png')" alt="" />
      </div>
      <div class="info_view">
        <span class="name_view">{{ info.name }}</span>
        <span class="phone_view">{{ phonestr(info.phone) }}</span>
      </div>
    </div>
    <div class="order_view">
      <div class="order_item_view" @click="toOrder(0)">
        <span class="my_order_view">我的订单</span>
        <div class="whole_view">
          <span>全部订单</span>
          <van-icon name="arrow" color="#ACACAC"></van-icon>
        </div>
      </div>
      <div class="order_icon_view" @click="toOrder(1)">
        <div>
          <van-badge>
            <img src="../../assets/stay_pay_bg.png" alt="" />
          </van-badge>
          <span>待支付</span>
        </div>
        <div @click="toOrder(2)">
          <van-badge>
            <img src="../../assets/stay_distribution_bg.png" alt="" />
          </van-badge>
          <span>待配送</span>
        </div>
        <div @click="toOrder(3)">
          <van-badge>
            <img src="../../assets/delivery_bg.png" alt="" />
          </van-badge>
          <span>配送中</span>
        </div>
        <div @click="toOrder(4)">
          <van-badge>
            <img src="../../assets/complete_bg.png" alt="" />
          </van-badge>
          <span>已完成</span>
        </div>

        <div @click="toOrder(5)">
          <van-badge>
            <img src="../../assets/cancel_bg.png" alt="" />
          </van-badge>
          <span>已取消</span>
        </div>
      </div>
    </div>
    <div class="my_serve_view">
      <span
        style="
          margin-top: 20px;
          margin-left: 20px;
          display: block;
          font-size: 16px;
          color: black;
        "
        >我的服务</span
      >
      <div @click="goToAddress(1)">
        <img src="../../assets/address_bg.png" alt="" />
        <span>收货地址</span>
      </div>
    </div>
    <div class="btn_view" @click="exit">退出登录</div>
    <!-- <van-button type="info" @click="login">登录</van-button> -->
  </div>
</template>

<script>
import { usergGtById, imgApp } from "@/service/api/index.js";
import { removeToken } from "@/utils/auth.js";

export default {
  data() {
    return {
      info: {
        // headImg
      },
    };
  },
  computed: {
    phonestr() {
      return function (phone) {
        if (phone) {
          return phone.substring(0, 3) + "****" + phone.substring(7, 11);
        }
      };
    },
  },
  mounted() {
    usergGtById().then((res) => {
      this.info = res.data;
    });
  },
  methods: {
    // login(){
    //     this.$router.push('/login')
    // }
    goToAddress(e) {
      if (e === 1) {
        this.$router.push("/address");
      } else if (e === 2) {
        this.$router.push("/confirmOrder");
      }
    },
    exit() {
      this.$dialog
        .confirm({
          message: "确定退出登录吗？",
        })
        .then(() => {
          // on confirm
          this.$router.push("/login");
          removeToken("token");
        })
        .catch(() => {
          // on cancel
        });
    },
    toOrder(e) {
      if (e == 0) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 0,
          },
        });
      } else if (e == 1) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 1,
          },
        });
      } else if (e == 2) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 2,
          },
        });
      } else if (e == 3) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 3,
          },
        });
      } else if (e == 4) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 4,
          },
        });
      } else if (e == 5) {
        this.$router.push({
          name: "Order",
          params: {
            tabIndex: 5,
          },
        });
      }
    },
    toUserCenter() {
      this.$router.push("/userCenter");
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .user_top_view {
    max-width: 540px;
    width: 100%;
    height: 278px;
    background-image: url("../../assets/person_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 278px;
    display: flex;
    flex-direction: row;
    .img_div {
      width: 60px;
      height: 60px;
      margin-left: 20px;
      margin-top: 54px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .info_view {
      margin-top: 56px;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      .name_view {
        font-size: 20px;
        color: white;
      }
      .phone_view {
        font-size: 12px;
        color: white;
        margin-top: 8px;
      }
    }
  }
  .order_view {
    max-width: 520px;
    margin: 0px auto;
    height: 125px;
    border-radius: 10px;
    background: white;
    .order_item_view {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .my_order_view {
        font-size: 16px;
        color: black;
        margin-top: 20px;
        margin-left: 20px;
      }
      .whole_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        margin-top: 20px;
        span {
          font-size: 12px;
          color: #acacac;
        }
      }
    }
  }
  .order_icon_view {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    div {
      display: flex;
      margin-top: 12px;
      flex-direction: column;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-bottom: 4px;
      }
      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .my_serve_view {
    max-width: 520px;
    height: 120px;
    margin: 0px auto;
    border-radius: 10px;
    background: white;
    div {
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      margin-left: 33px;
      font-size: 12px;
      color: #999999;
      img {
        width: 32px;
        height: 32px;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .btn_view {
    max-width: 520px;
    margin: 0px auto;
    height: 36px;
    background: white;
    color: red;
    border: none;
    line-height: 36px;
    position: absolute;
    border-radius: 30px;
    font-size: 13px;
    text-align: center;
    bottom: 55px;
    left: 10px;
    right: 10px;
  }
}
.user_top_view {
  width: 100%;
  height: 278px;
  background-image: url("../../assets/person_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 278px;
  display: flex;
  flex-direction: row;
  .img_div {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-top: 54px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .info_view {
    margin-top: 56px;
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    .name_view {
      font-size: 20px;
      color: white;
    }
    .phone_view {
      font-size: 12px;
      color: white;
      margin-top: 8px;
    }
  }
}
.order_view {
  height: 125px;
  position: absolute;
  top: 140px;
  right: 10px;
  left: 10px;
  border-radius: 10px;
  background: white;
  .order_item_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .my_order_view {
      font-size: 16px;
      color: black;
      margin-top: 20px;
      margin-left: 20px;
    }
    .whole_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      margin-top: 20px;
      span {
        font-size: 12px;
        color: #acacac;
      }
    }
  }
}
.order_icon_view {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  div {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 4px;
    }
    span {
      font-size: 12px;
      color: #999999;
    }
  }
}
.my_serve_view {
  height: 120px;
  position: absolute;
  top: 275px;
  right: 10px;
  left: 10px;
  bottom: 240px;
  border-radius: 10px;
  background: white;
  div {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    margin-left: 33px;
    font-size: 12px;
    color: #999999;
    img {
      width: 32px;
      height: 32px;
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }
}
.btn_view {
  height: 36px;
  background: white;
  color: red;
  border: none;
  line-height: 36px;
  position: absolute;
  border-radius: 30px;
  font-size: 13px;
  text-align: center;
  bottom: 55px;
  left: 10px;
  right: 10px;
}
</style>
