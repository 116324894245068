<template>
  <div id="app">
    <!-- <router-view> -->
      <AppTabbar v-show="$route.meta.isShowTabbar"/>
      <keep-alive include="newAddress,category">
        <router-view/>
      </keep-alive>
  </div>
</template>
<script>
import AppTabbar from '@/components/AppTabbar'
export default {
  components:{
    AppTabbar
  }
}
</script>

<style lang="less">
@media screen and (min-width:768px) {
  #app{
    max-width: 540px;
    margin: 0px auto;
  }
}
#app {
  height: 100%;
  font-family: '苹方-简 常规体';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F5F6;
  min-height: 100%;
  font-size: .14rem ;
}
html,body {
  height: 100%;
}
body{
  background-color:aliceblue;;
}
html {
  font-size: 100px !important;   // 16px?? 
}
.active,.actives {
    .van-tabbar{
      height: 2.929rem;
    }
    .van-icon{
      font-size:1.3rem;
      margin-bottom: 0.16rem;
    }
    .van-tabbar-item{
      font-size:0.73rem
    }
  }

</style>
