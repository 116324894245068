import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AMap from 'vue-amap'
import './vantui'
import 'lib-flexible'
import 'amfe-flexible/index.js'
import './permission' // permission control
import md5 from 'js-md5'
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp);
Vue.prototype.$md5 = md5;
// Vue.use(AMap)
// // 初始化vue-amap
// AMap.initAMapApiLoader({
//   // 高德key
//   key: '3947fa1c72d66994d6c17537cebb158a',
//   // 插件集合 （插件按需引入）
//   plugin: ['AMap.Geolocation']
// });
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
