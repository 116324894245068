import router from './router'
import { getToken } from '@/utils/auth.js'
import { Toast } from "vant";


const whiteList = ['/login', '/home', '/', '/category', '/goodsDetail', '/areaCodeSelection', '/forgetPassword', '/changeNewPassword', '/wxLogin', '/changePwd', '/agreement'] // no redirect whitelist


router.beforeEach(async (to, from, next) => {
  const token = getToken('token')
  if (token) {
    if ((to.name == 'AreaCodeSelection' || to.name === 'ChangeNewPassword') && from.name == 'Login') {
      next(`/home`)
    } else {
      if (from.path == "/mapAddress" || from.path == "/areaCodeSelection" || from.path == "/goodsDetail") {
        to.meta.isBack = true;
      } else {
        to.meta.isBack = false;
      }
      next()
    }


  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {

      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`)
    }
  }
  // const { roles } = await store.dispatch('user/getInfo')
  // dynamically add accessible routes
  // router.addRoutes(accessRoutes)
})


