const TokenKey = 'userInfo'

function key(name) {
  return name ? name : TokenKey
}
export function getToken(name) {
  return sessionStorage.getItem(key(name))
}

export function setToken(token, name) {
  return sessionStorage.setItem(key(name), token)
}

export function removeToken(name) {
  return sessionStorage.removeItem(key(name))
}

// 加法
export function accAdd(arg1 = '', arg2 = '') {
  var r1, r2, m
  try { r1 = (arg1).toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = (arg2).toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}
// 减法
export function Subtr(arg1 = '', arg2 = '') {
  var r1, r2, m, n
  try { r1 = (arg1).toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = (arg2).toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}
// 乘法
export function accMul(arg1 = '', arg2 = '') {
  var m = 0; var s1 = (arg1).toString(); var s2 = (arg2).toString()
  try { m += s1.split('.')[1].length } catch (e) { }
  try { m += s2.split('.')[1].length } catch (e) { }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}
// 除法
export function accDiv(arg1 = '', arg2 = '') {
  var t1 = 0; var t2 = 0; var r1; var r2
  try { t1 = (arg1).toString().split('.')[1].length } catch (e) { }
  try { t2 = (arg2).toString().split('.')[1].length } catch (e) { }
  // with (Math) {
  r1 = Number((arg1).toString().replace('.', ''))
  r2 = Number((arg2).toString().replace('.', ''))
  return accMul((r1 / r2), Math.pow(10, t2 - t1))
  // }
}