<template>
  	<div class="container_view">
    	<van-tabbar active-color="#00B05A" inactive-color="#B6B6B6" id="AppTabbar" class="bottom_view" v-model="$route.meta.activeNum" route>
			  <van-tabbar-item to="/home"  icon="home-o">
			  	<span>首页</span> 
				<template #icon="props">
      				<img :src="props.active ? icon.active : icon.inactive" />
				</template>
			  </van-tabbar-item>
      		<van-tabbar-item to="/category" >
				<span>分类</span>
				<template #icon="props">
      				<img :src="props.active ? icon.active1 : icon.inactive1" />
				</template>  
			</van-tabbar-item>
      		<van-tabbar-item to="/cart">
			  	<span>购物车</span>
				<template #icon="props">
      				<img :src="props.active ? icon.active2 : icon.inactive2" />
				</template>
			</van-tabbar-item>
      		<van-tabbar-item to="/order">
				<span>订单</span>
				<template #icon="props">
      				<img :src="props.active ? icon.active3 : icon.inactive3" />
				</template>  
			</van-tabbar-item>
      		<van-tabbar-item to="/user">
				  <span>我的</span>
				<template #icon="props">
      				<img :src="props.active ? icon.active3 : icon.inactive3" />
				</template>  
			</van-tabbar-item>
    	</van-tabbar>
		
  	</div>
</template>

<script>
export default {
  	data() {
    	return {
			// active: 0,
			icon: {
        		active: require("../assets/home_tab_select_bg.png"),
        		inactive: require("../assets/home_tab_no_select_bg.png"),
				active1: require("../assets/category_tab_select_bg.png"),
        		inactive1: require("../assets/category_tab_no_select_bg.png"),
				active2: require("../assets/cart_tab_select_bg.png"),
        		inactive2: require("../assets/cart_tab_no_select_bg.png"),
				active3: require("../assets/order_tab_select_bg.png"),
        		inactive3: require("../assets/order_tab_no_select_bg.png"),
				active4: require("../assets/person_tab_select_bg.png"),
        		inactive4: require("../assets/person_tab_no_select_bg.png"),
      		},

		};
  	},
  	computed: {
    	active() {
      		return this.$route.meta.activeNum;
    	},
  	},
};
</script>
 
<style lang = "less" scoped>
	@media screen and (min-width:768px) {
		.bottom_view{
			max-width: 540px;
			margin-left: 50%;
			transform: translateX(-50%);
		}
	}
</style>