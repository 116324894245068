import axios from 'axios'
import { Toast } from "vant";
import router from '../../router'
import { removeToken } from '@/utils/auth.js'

export default function ajxa(url = '', params = {}, type = 'GET') {
  //1 变量
  let promise;

  //2 返回一个promise对象
  return new Promise((resolve, reject) => {
    //2.1 判断请求的类型
    if (type.toUpperCase() === 'GET') {
      promise = axios.get(formatUrl(url, params));
    } else if (type.toUpperCase() === 'POST') {
      //2.7 发起post请求
      promise = axios.post(url, params);
    } else if (type.toUpperCase() === 'POST_PARAMS') {
      //2.6 发起get请求
      promise = axios.post(formatUrl(url, params.params), params.data);
    } else if (type.toUpperCase() === 'PUT') {
      promise = axios.put(url, params);

    } else if (type.toUpperCase() === 'DELETE') {
      promise = axios.delete(formatUrl(url, params))
    }
    //2.8 处理结果并返回
    promise.then((response) => {
      resolve(response.data);
    }).catch(error => {
      reject(error);
    });
  });

}
axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token')
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = token; //请求头加上token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })
axios.interceptors.response.use(
  response => {
    // console.log(123)
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.status != 200) {
      Toast(res.message);
    }
    if (res.status == 401) {
      removeToken('token')
      router.push("/login")
    }
    return response
  },
  error => {
    // Toast("接口异常");
    // console.log("error",error)
    // console.log("reject",Promise.reject(error).)
    return Promise.reject(error)
  }
)

function formatUrl(originUrl, params) {
  let paramsStr = '';
  //2.3 遍历
  Object.keys(params).forEach(key => {
    paramsStr += key + '=' + params[key] + '&';
  });
  if (paramsStr) {
    paramsStr = paramsStr.substr(0, paramsStr.lastIndexOf('&'));
  }
  originUrl += '?' + paramsStr;
  return originUrl;
}
