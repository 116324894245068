import Vue from 'vue'
import { Toast } from 'vant';
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import User from '../views/user/User.vue'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
}
Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: "/home"
},
{
  path: '/home',
  name: 'Home',
  component: Home,
  meta: {
    activeNum: 0,
    isShowTabbar: true
  }
},
{
  path: '/category',
  name: 'Category',
  meta: {
    activeNum: 1,
    isShowTabbar: true,
    keepAlive: true,
    isBack: false,
  },
  component: () => import('../views/category/Category.vue')
},
{
  path: '/cart',
  name: 'Cart',
  meta: {
    activeNum: 2,
    isShowTabbar: true
  },
  component: () => import(/* webpackChunkName: "Category" */'../views/cart/Cart.vue')
},
{
  path: '/order',
  name: 'Order',
  meta: {
    activeNum: 3,
    isShowTabbar: true
  },
  component: () => import(/* webpackChunkName: "Category" */'../views/order/Order.vue')
},
{
  path: '/user',
  name: 'User',
  component: User,
  meta: {
    activeNum: 4,
    isShowTabbar: true
  },
  children: [{
    path: 'login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Category" */'../views/login/Login.vue')
  }]
},
{
  path: '/userCenter',
  name: 'UserCenter',
  component: () => import('../views/user/children/UserCenter.vue')
},
{
  path: '/nickName',
  name: 'NickName',
  component: () => import('../views/user/children/NickName.vue')
},
{
  path: '/changePwd',
  name: 'ChangePwd',
  component: () => import('../views/user/children/ChangePwd.vue')
},
{
  path: '/login',
  name: 'Login',
  component: () => import('../views/login/Login.vue')
},
{
  path: '/agreement',
  name: 'Agreement',
  component: () => import('../views/login/children/Agreement.vue')
},
{
  path: '/latelySx',
  name: 'LateLySx',
  component: () => import('../views/latelysx/LateLySx.vue')
},
{
  path: '/qualitySelection',
  name: 'QualitySelection',
  component: () => import('../views/qualityselection/index.vue')
},
{
  path: '/zf',
  name: 'zf',
  component: () => import('../views/zf/index.vue')
},
{
  path: '/orderDetail',
  name: 'OrderDetail',
  component: () => import('../views/orderdetail/OrderDetail.vue')
}, {
  path: '/address',
  name: 'Address',
  component: () => import('../views/address/Address.vue')
}, {
  path: '/newAddress',
  name: 'NewAddress',
  meta: {
    keepAlive: true,
    isBack: false,
  },
  component: () => import('../views/address/children/NewAddress.vue')
}, {
  path: '/editAddress',
  name: 'EditAddress',
  component: () => import('../views/address/children/EditAddress.vue')
}, {
  path: '/confirmOrder',
  name: "ConfirmOrder",
  component: () => import('../views/order/children/ConfirmOrder.vue')
}, {
  path: '/successOrder',
  name: 'SuccessOrder',
  component: () => import('../views/order/children/SuccessOrder.vue')
}, {
  path: '/againOrder',
  name: 'AgainOrder',
  component: () => import('../views/order/children/AgainOrder.vue')

}, {
  path: '/areaCodeSelection',
  name: 'AreaCodeSelection',
  component: () => import('../views/login/children/AreaCodeSelection.vue')
}, {
  path: '/selectAddress',
  name: 'SelectAddress',
  component: () => import('../views/address/children/SelectAddress.vue')
}, {
  path: '/searchAddress',
  name: 'SearchAddress',
  component: () => import('../views/address/children/SearchAddress.vue')
},

{
  path: '/detailsAddress',
  name: 'DetailsAddress',
  component: () => import('../views/address/children/DetailsAddress.vue')
},
{
  path: '/goodsDetail',
  name: 'GoodsDetail',
  component: () => import('../views/goodsdetail/GoodsDetail.vue')
}, {
  path: '/mapAddress',
  name: 'MapAddress',
  component: () => import('../views/address/children/MapAddress.vue')
}, {
  path: '/forgetPassword',
  name: 'ForgetPassword',
  component: () => import('../views/login/children/ForgetPassword.vue')
}, {
  path: '/changeNewPassword',
  name: 'ChangeNewPassword',
  component: () => import('../views/login/children/ChangeNewPassword.vue')
},{
  path: '/detail',
  name: 'Detail',
  component: () => import('../views/home/detail.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
