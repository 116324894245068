<template>
  <div class="container_view">
    <img class="top_arrow_view" @click="toTop" src="../../assets/top_arrow_bg.png" />
    <div class="home_map_icon_view">
      <div @click="myAddress">
        <img src="../../assets/map_icon_bg.png" alt />
        <span>{{ address || "我的位置" }}</span>
      </div>

      <div class="home_search_view" @click="toSearchPage">
        <van-icon name="search" style="margin-left: 5px" size="15px" color="#B5B5B5" />
        <span style="font-size: 12px; color: #b5b5b5">搜索商品</span>
      </div>
    </div>

    <!--轮播图-->
    <van-swipe :autoplay="3000" style="margin-top: 26px">
      <van-swipe-item
        class="van_swipe_item"
        v-for="(item, index) in images"
        :key="index"
        @click="bannerClick(item)"
      >
        <img v-lazy="item.img" />
      </van-swipe-item>
    </van-swipe>
    <!--轮播分类-->
    <van-swipe style="margin-top: 32px">
      <van-swipe-item>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          "
        >
          <div
            class="fl_view"
            v-for="(item, index) in list1"
            :key="index"
            @click="toCategory(item)"
          >
            <img :src="imgurl + item.img" alt />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 20px;
          "
        >
          <div
            class="fl_view"
            v-for="(item, index) in list2"
            :key="index"
            @click="toCategory(item)"
          >
            <img :src="imgurl + item.img" alt />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!--icon_view-->
    <icon-view></icon-view>
    <!--最近上新-->
    <horizontal-good></horizontal-good>
    <quality-selection></quality-selection>
  </div>
</template>

<script>
import { setToken } from "@/utils/auth.js";
import IconView from "../../components/IconView";
import HorizontalGood from "../../components/HorizontalGood";
import QualitySelection from "../../components/QualitySelection";
import {
  index,
  imgApp,
  adManage_getList,
  login_user_callBack
} from "@/service/api/index.js";
export default {
  components: {
    IconView,
    HorizontalGood,
    QualitySelection
  },
  data() {
    return {
      imgurl: "",
      list1: [],
      list2: [],
      images: [],
      address: "",
      userAgent: ""
    };
  },
  created() {
    let data = {
      key: "3AABZ-RG3LX-2LT4M-ZPY3C-OIYVV-RFFWW" //申请的密钥
    };
    let url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
    data.output = "jsonp";
    this.$jsonp(url, data)
      .then(res => {
        this.address = res.result.ad_info.province + res.result.ad_info.city;
        console.log("这是地址" + JSON.stringify(this.address));
        this.position.latitude = res.result.location.lat;
        this.position.longitude = res.result.location.lng;
      })
      .catch(error => {
        console.log(error);
      });
  },
  mounted() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
    if (this.$route.query.state === "wxRedirect") {
      login_user_callBack({
        code: this.$route.query.code,
        isPc: true
      }).then(res => {
        setToken(res.data.token, "token");
        setToken(res.data.userInfo.openId, "openId");
        const { phone } = res.data.userInfo;
        if (!phone) {
          this.$router.push({
            path: "/changePwd",
            query: {
              state: "wxRedirect"
            }
          });
        }
      });
    }
    console.log(process.env.NODE_ENV, "aaa");
    this.imgurl = imgApp;
    index()
      .then(res => {
        this.list1 = res.data.category.slice(0, 4);
        this.list2 = res.data.category.slice(4, 8);
        // this.list2 = res.data.category
      })
      .catch(err => {});
    adManage_getList().then(res => {
      if (res.status == 200) {
        // this.images = res.data.map((el) => el.img = imgApp + el.img);
        for (let i in res.data) {
          res.data[i].img = imgApp + res.data[i].img;
          this.images.push(res.data[i]);
        }
        console.log(this.images);
      }
    });
  },
  methods: {
    toTop() {
      let timer = setInterval(function() {
        var distanceY =
          document.documentElement.scrollTop || document.body.scrollTop; //兼容
        if (distanceY == 0) {
          clearInterval(timer);
          return;
        }
        var speed = Math.ceil(distanceY / 1); //speed这个值从高变低，那么scrollTop就减得从快到慢，上回到顶部的速度就先快后慢
        document.documentElement.scrollTop = distanceY - speed;
        // document.documentElement.scrollTop=distanceY-5;//如果给速度一个确定的值，那回到顶部的就匀速
      }, 16);
    },
    myAddress() {
      this.$router.push("/selectAddress");
    },
    toCategory(item) {
      this.$router.push({ name: "Category", params: { id: item.id } });
    },
    toSearchPage() {
      this.$router.push("/searchAddress");
    },
    bannerClick(data) {
      if (data.type == "模块") {
        if (data.moduleId == 1) {
          this.$router.push("/latelySx");
        } else {
          this.$router.push("/qualitySelection");
        }
      } else if (data.type == "分类") {
        let arr = data.categorys.split(",");
        this.$router.push({
          name: "Category",
          params: { id: arr[0], childrenId: arr[1] }
        });
      } else {
        // this.$router.push("/detail");
        this.$router.push({ name: "Detail", params: { title:data.h5Title,content:data.h5Content } });

        // console.log(data)
        // h5
      }
    }
  }
};
</script>

<style lang="less" scoped>
// @media screen and (max-width:540px) {
.van_swipe_item {
  height: 200px;

  img {
    height: 200px;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.top_arrow_view {
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 90px;
  right: 6px;
  z-index: 2;
}

.home_map_icon_view {
  max-width: 540px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 44px;
  position: fixed;
  justify-content: space-between;
  z-index: 10;
  padding-left: 10px;
  top: 0;
  background: white;
}

.home_map_icon_view .home_search_view {
  width: 60%;
  height: 25px;
  background: white;
  border-radius: 40px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #b5b5b5;
}

.home_map_icon_view div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home_map_icon_view div img {
  width: 22px;
  height: 22px;
}

.home_map_icon_view div span {
  font-size: 16px;
  color: #3f3f3f;
}

.fl_view {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 54px;
    height: 54px;
    margin-bottom: 3px;
  }

  span {
    font-size: 12px;
    color: #3f3f3f;
  }
}

// }
// @media screen and (min-width:768px) {
//     .container_view{
//         max-width: 540px;
//     }
//     .van_swipe_item{
//         height: 200px;
//         img{
//             height:200px;
//             width:100%;
//             max-width:100%;
//             max-height:100%
//         }
//     }
//     .top_arrow_view{
//         width:44px;
//         height:44px;
//         position:fixed;
//         bottom: 90px;
//         right: 6px;
//         z-index: 2;
//     }

//     .home_map_icon_view{
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         width: 100%;
//         height: 44px;
//         position: fixed;
//         z-index: 10;
//         padding-left: 10px;
//         top: 0;
//         background: white;
//     }
//     .home_map_icon_view img{
//         width: 22px;
//         height: 22px;
//     }
//     .home_map_icon_view span{
//         font-size: 16px;
//         color: #3F3F3F;
//     }
//     .fl_view{
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         img{
//             width: 54px;
//             height: 54px;
//             margin-bottom: 3px;
//         }
//         span{
//             font-size: 12px;
//             color: #3F3F3F;
//         }
//     }
// }
</style>
