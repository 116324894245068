<template>
  <div>
    <div class="sx_view">
      <div class="see_more_view" @click="goToLateSxList">
        <span>查看更多</span>
        <img src="../assets/home_right_arrow_bg.png" alt />
      </div>
      <div class="recommand-wrap">
        <div ref="wrapper">
          <ul class="cont" ref="cont" :class="[userAgent == 'PC' ? 'demo' : '']">
            <li
              @click="toGoodsDetail(item)"
              class="cont-item"
              v-for="item of recommendList"
              :key="item.id"
            >
              <div class="cont-img">
                <!-- 次日达图标 -->
                <img
                  v-show="(item.stockingTime == 1 || item.stockingTime == 2) && item.totalStock <= 0"
                  class="torrmow_img"
                  src="@/assets/ic_torrmow.png"
                  alt
                />
                <img class="img" style="position: absolute" v-show="item.totalStock<= 0 && item.stockingTime >= 30" src="@/assets/shoujin_bg.png">
                <!-- <img v-show="item.totalStock<= 0" src="@/assets/shoujin_bg.png"> -->
                <img class="img" :src="imgurl + item.image" :alt="item.name" />
                <div class="name_view">
                  <span>{{ item.name }}</span>
                </div>
                <div class="cont-price">
                  <div class="price_view">
                    <span>₱</span>
                    <span class="price">{{ item.price }}</span>
                  </div>
                  <!-- 最近上新旁边灰色小字 -->
                  <!-- <div class="marketprice_view">
                    <span>₱</span>
                    <span>{{ item.basePriceMarket }}</span>
                  </div>-->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";

import {
  goods_hotGoods,
  imgApp,
  module_getItemAndCartNum
} from "@/service/api/index.js";
export default {
  components: {},
  data() {
    return {
      imgurl: "",
      recommendList: [],
      pageIndex: 1,
      pageSize: 9999
    };
  },
  created() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
  },
  methods: {
    module_getItemAndCartNum() {
      module_getItemAndCartNum({
        id: 1,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.status == 200) {
          this.recommendList = res.data.vos;
          console.log('recommendList',this.recommendList)
        }
      });
    },
    // verScroll () {
    //   let width = this.recommendList.length * 90// 动态计算出滚动区域的大小，前面已经说过了，产生滚动的原因是滚动区域宽度大于父盒子宽度
    //   this.$refs.cont.style.width = width + 'px'  // 修改滚动区域的宽度
    //   this.$nextTick(() => {
    //     if (!this.scroll) {
    //       this.scroll = new BScroll(this.$refs.wrapper, {
    //         startX: 0,  // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
    //         click: true,
    //         scrollX: true,
    //         scrollY: false,
    //         eventPassthrough: 'vertical'
    //       })
    //     } else {
    //       this.scroll.refresh() //如果dom结构发生改变调用该方法
    //     }
    //   })
    // },
    goToLateSxList() {
      this.$router.push("/latelySx");
    },
    toGoodsDetail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: item.id
        }
      });
    }
  },
  mounted() {
    this.imgurl = imgApp;
    this.module_getItemAndCartNum();
    this.$nextTick(() => {
      let timer = setTimeout(() => {
        // 其实我也不想写这个定时器的，这相当于又嵌套了一层$nextTick，但是不这么写会失败
        if (timer) {
          clearTimeout(timer);
          // this.verScroll();
        }
      }, 0);
    });
  }
};
</script>

<style lang="less" scoped>
.torrmow_img {
  width: 70px;
  position: absolute;
  z-index: 10;
  left: 0;
}
.demo::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* 设置滚动条的滑轨 */
.demo::-webkit-scrollbar-track {
  background-color: #ddd;
}

/* 滑块 */
.demo::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* 滑轨两头的监听按钮 */
.demo::-webkit-scrollbar-button {
  background-color: #888;
  display: none;
}
// @media screen and (max-width:540px) {
.sx_view {
  width: 100%;
  height: 300px;
  position: relative;
  background-image: url("../assets/sx_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  .see_more_view {
    width: 70px;
    height: 18px;
    border: 0.6px solid #5ba88d;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    color: #5ba88d;
    justify-content: center;
    font-size: 10px;
    right: 15px;
    top: 25px;
    img {
      width: 7px;
      height: 7px;
    }
  }
  .recommand-wrap {
    position: absolute;
    top: 70px;
    width: 98%;
    .cont {
      list-style: none;
      overflow-x: scroll;
      // overflow: hidden;
      white-space: wrap;
      font-size: 12px;
      width: 100%;
      padding-left: 5px;
      display: flex;
      flex-direction: row;
      .cont-item {
        display: flex;
        flex-direction: column;
        width: 50%;
        // box-shadow: darkgrey 0px 8px 8px 0px ;//边框阴影
        box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);
        height: 100%;
        margin-right: 5px;
        background: white;
        border-radius: 8px;
        .cont-img {
          width: 100%;
          height: 200px;
          margin-top: 9px;
          margin-left: 50%;
          transform: translateX(-38%);
          .img {
            width: 104px;
            height: 104px;
          }
          .name_view {
            width: 107px;
            display: flex;
            flex-wrap: wrap;
            margin-right: 30px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            margin-top: 11px;
            margin-bottom: 6px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            span {
              width: 100%;
              white-space: pre-wrap;
            }
          }
          .cont-price {
            display: flex;
            flex-direction: row;
            .price_view span:first-of-type {
              color: #df3436;
              font-size: 12px;
            }
            .price_view span:last-of-type {
              color: #df3436;
              font-size: 18px;
            }
            .marketprice_view span:first-of-type {
              color: #acacac;
              display: inline-block;
              font-size: 3px;
              margin-left: 3px;
              margin-top: 8px;
            }
            .marketprice_view span:last-of-type {
              color: #acacac;
              display: inline-block;
              font-size: 9px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
// }
// @media screen and (min-width:768px) {
//     .sx_view{
//         width: 100%;
//         height: 500px;
//         position: relative;
//         background-image: url('../assets/sx_bg.png');
//         background-size: contain;
//         background-repeat: no-repeat;
//         .see_more_view{
//             width: 70px;
//             height: 18px;
//             border: 0.6px solid #5BA88D;
//             border-radius: 30px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             position: absolute;
//             color: #5BA88D;
//             justify-content: center;
//             font-size: 10px;
//             right: 15px;
//             top: 25px;
//             img{
//                 width: 7px;
//                 height: 7px;
//             }
//         }
//         .recommand-wrap {
//             position: absolute;
//             top: 150px;
//             width: 100%;
//             .cont {
//             list-style: none;
//             overflow-x: scroll;
//             // overflow: hidden;
//             white-space: wrap;
//             font-size: 14px;
//             height: 100%;
//             width: 100%;
//             padding-left: 5px;
//             display: flex;
//             flex-direction: row;
//             .cont-item {
//                 display: flex;
//                 flex-direction: column;
//                 width: 50%;
//                 margin-right: 5px;
//                 // box-shadow: darkgrey 0px 8px 8px 0px ;//边框阴影
//                 box-shadow: 0px 10px 10px 0px rgba(153, 153, 153, 0.1);
//                 height: 100%;
//                 background: white;
//                 border-radius: 8px;
//                 .cont-img {
//                     width: 100%;
//                     height: 100%;
//                     margin-left: 12px;
//                     margin-top: 9px;
//                     .img {
//                         width: 280px;
//                         height:280px;
//                     }
//                     .name_view{
//                         width: 100%;
//                         display: flex;
//                         flex-wrap: wrap;
//                         margin-right: 30px;
//                         text-overflow: -o-ellipsis-lastline;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 2;
//                         -webkit-box-orient: vertical;
//                         span{
//                             width: 100%;
//                             white-space:pre-wrap
//                         }
//                     }
//                     .cont-price{
//                         display: flex;
//                         flex-direction: row;
//                         .price_view span:first-of-type{
//                             color: #DF3436;
//                             font-size: 12px;
//                         }
//                         .price_view span:last-of-type{
//                             color: #DF3436;
//                             font-size: 18px;
//                         }
//                         .marketprice_view span:first-of-type{
//                             color: #ACACAC;
//                             display: inline-block;
//                             font-size: 3px;
//                             margin-left: 3px;
//                             margin-top: 8px;
//                         }
//                         .marketprice_view span:last-of-type{
//                             color: #ACACAC;
//                             display: inline-block;
//                             font-size: 9px;
//                             margin-top: 8px;
//                         }
//                     }
//                 }
//             }
//             }
//         }
//     }
// }
</style>